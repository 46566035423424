const UsuariosPage = () => import(/* webpackChunkName: "usuarios-page" */ '@/views/cadastros-basicos/UsuariosPage')
const SetoresPage = () => import(/* webpackChunkName: "setores-page" */ '@/views/cadastros-basicos/SetoresPage')



const CanaisPage = () => import(/* webpackChunkName: "canais-page" */ '@/views/cadastros-basicos/CanaisPage')


const NewCanaisPage = () => import(/* webpackChunkName: "new-canais-page" */ '@/views/cadastros-basicos/NewCanaisPage.vue')
const setorNewScreen = () => import(/* webpackChunkName: "setor-new-screen" */ '@/views/cadastros-basicos/setorNewScreen.vue')
//const clientesNewPage = () => import(/* webpackChunkName: " clientes-new-page" */ '@/views/cadastros-basicos/clientesNewPage.vue')


const ConfiguracaogptPage = () => import(/* webpackChunkName: "canais-page" */ '@/views/cadastros-basicos/ConfiguracaogptPage')
const TabulacaoPage = () => import(/* webpackChunkName: "tabulacao-page" */ '@/views/cadastros-basicos/TabulacaoPage')
const ChatConfiguracaoPage = () => import(/* webpackChunkName: "chat-configuracao-page" */ '@/views/cadastros-basicos/ChatConfiguracaoPage.vue')
const ConfiguracoesPage = () => import(/* webpackChunkName: "configuracoes-page" */ '@/views/cadastros-basicos/ConfiguracoesPage.vue')

const FormulariosPage = () => import(/* webpackChunkName: "config-shark-page" */ '@/views/cadastros-basicos/formulario-gerenciar/FormulariosPage.vue')
const FormulariosRelatoriosPage = () => import(/* webpackChunkName: "config-shark-page" */ '@/views/cadastros-basicos/formulario-gerenciar/FormulariosRelatoriosPage.vue')
const GerenciarFormulariosPage = () => import(/* webpackChunkName: "config-shark-page" */ '@/views/cadastros-basicos/GerenciarFormulariosPage.vue')

const DiscoVirtualPage = () => import(/* webpackChunkName: "discovirtual-page" */ '@/views/cadastros-basicos/DiscoVirtualPage.vue')
const ConfiguradoesPausesPage = () => import(/* webpackChunkName: "configuracoes-Pause" */ '@/views/cadastros-basicos/ConfiguracoesPausasPage.vue')
const TemplatesMensagemPage = () => import(/* webpackChunkName: "templates-mensagem" */ "@/views/cadastros-basicos/TemplatesMensagemPage.vue");
const RamaisPage = () => import(/* webpackChunkName: "templates-mensagem" */ "@/views/cadastros-basicos/RamaisPage.vue");

const ProdutosPage = () => import(/* webpackChunkName: "produtos-page" */ '@/views/cadastros-basicos/ProdutosPage.vue')

const CategoriasProdutosPage = () => import(/* webpackChunkName: "categorias-produtos-page" */ '@/views/produtos/CategoriasProdutosPage.vue')
const ProdutosProdutosPage = () => import(/* webpackChunkName: "produtos-produtos-page" */ '@/views/produtos/ProdutosProdutosPage.vue')

const ClientesPage = () => import(/* webpackChunkName: "clientes-page" */ '@/views/cadastros-basicos/relacoes-clientes/ClientesPage.vue')
const VagasPage = () => import(/* webpackChunkName: "vagas-page" */ '@/views/cadastros-basicos/relacoes-clientes/VagasPage.vue')
export default [
    
    

    {
       path: '/canais_NovaTela',
       name: 'NewCanaisPage',
       component: NewCanaisPage
    },



    {
        path: '/setorNewPage',
        name: 'setorNewScreen',
        component: setorNewScreen
    },




    {
        path: '/chat-configuracao',
        name: 'ChatConfiguracaoPage',
        component: ChatConfiguracaoPage
    },
    {
        path: '/usuarios',
        name: 'UsuariosPage',
        component: UsuariosPage
    },
    {
        path: '/setores',
        name: 'SetoresPage',
        component: SetoresPage
    },
    {
        path: '/canais',
        name: 'CanaisPage',
        component: CanaisPage
    },
    {
        path: '/tabulacao',
        name: 'TabulacaoPage',
        component: TabulacaoPage
    },
    {
        path: '/configuracoes',
        name: 'ConfiguracoesPage',
        component: ConfiguracoesPage
    },
    {
        path: '/formularios',
        name: 'FormulariosPage',
        component: FormulariosPage
    },
    {
        path: '/formularios-relatorios',
        name: 'FormulariosRelatoriosPage',
        component: FormulariosRelatoriosPage
    },
    {
        path: '/gerenciarformularios',
        name: 'GerenciarFormulariosPage',
        component: GerenciarFormulariosPage
    },
    {
        path: '/disco-virtual',
        name: 'DiscoVirtualPage',
        component: DiscoVirtualPage
    },
    {
        path: '/produtos',
        name: 'ProdutosPage',
        component: ProdutosPage
    },
    {
        path: '/produtos-categorias',
        name: 'CategoriasProdutosPage',
        component: CategoriasProdutosPage
    },
    {
        path: '/produtos-produtos',
        name: 'ProdutosProdutosPage',
        component: ProdutosProdutosPage
    },
    {
        path: '/configuracoes-pausas',
        name: 'ConfiguradoesPausesPage',
        component: ConfiguradoesPausesPage
    },
	{
		path: '/templates-mensagem',
        name: 'TemplatesMensagemPage',
        component: TemplatesMensagemPage
    },
	{
		path: '/ramais',
        name: 'RamaisPage',
        component: RamaisPage
    },
    {
        path: '/Configuracaogpt',
        name: 'ConfiguracaogptPage',
        component: ConfiguracaogptPage
	},

    // Relações clientes
    {
        path: '/clientes',
        name: 'Clientes',
        component: ClientesPage
    },
    {
        path: '/vagas',
        name: 'VagasPage',
        component: VagasPage
    },
]
