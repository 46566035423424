<template>
    <div id="menu-top" class="menu-top-container cor-menu-cliente">
        <b-overlay :show="loading" rounded="sm">
            <div class="container-fluid" :aria-hidden="loading ? 'true' : null">
                <div id="caixa_carregamento">
                    <div class="loader"></div>
                </div>
                <div class="row menu-top-row cor-menu-cliente">

                    <div class="col-6 pl-0">

                        <botao-icone class="menu-top-item"
                                     :classeIcone="'menu-top-icone'"
                                     @click="$emit('toggle')" />

                        <a class="menu-top-item menu-top-link-home" href="/">
                            <span class="menu-top-titulo-head">SD-OMNI</span>
                        </a>

                    </div>
                    <div class="col-6 px-0">

                        <div class="float-right mr-2">
                            <b-dropdown right id="menu-top-dropdown" :text="usuarioLogado" class="menu-top-dropdown">
                                <b-dropdown-item v-if="usuarioWhatsWeb == true" @click="$emit('alterarNome')"><i class="icofont-refresh pr-1"></i> Alterar nome</b-dropdown-item>
                                <b-dropdown-item @click="$emit('redefinir')"><i class="icofont-refresh pr-1"></i> Trocar Senha</b-dropdown-item>
                                <b-dropdown-item @click="$emit('logout')"><i class="icofont-sign-out pr-1"></i>Desconectar</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <!-- Ap�s o almo�o -->
                        <div class="float-right mx-2" v-if="getPausas.length > 0">
                            <div class="dropdown">
                                <b-dropdown class="scroll-dropdown" size="sm" variant="transparent" toggle-class="text-decoration-none" no-caret right>
                                    <template #button-content>
                                        <botao-icone class="menu-top-botao-atendimento p-0"
                                                     :title="getIsUsuarioPausado? 'Liberar Atendimentos' : 'Pausar Atendimentos'"
                                                     :classeIcone="getIsUsuarioPausado? 'fa-play-circle' : 'fa-pause-circle'" />
                                    </template>
                                    <b-dropdown-item v-for="item in getPausas" :key="item.id" @click="pausar(item)">{{item.Nome}}</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>

                    </div>

                    <b-modal hide-footer v-model="getIsUsuarioPausado" centered no-close-on-backdrop id="modal-1" hide-header-close :title="'Pausa - ' + getNomePausa" cancel-disabled>
                        <template #default="{ ok }">
                            <b-container>
                                <div class="row justify-content-center" ref="elaspedTime">
                                    <span class="text-center"
                                          style="font-size: 35px;"
                                          :class="(tempoLimite && parseInt(tempoLimite.split(':')[0]) <= parseInt(minutos) && (tempoLimite && parseInt(tempoLimite.split(':')[0]) < parseInt(minutos) || parseInt(tempoLimite.split(':')[1]) < parseInt(segundos))) ? 'text-danger' : ''">
                                        {{ minutos.toString().padStart(2, "0") }}:{{ segundos.toString().padStart(2, "0") }}
                                    </span>
                                </div>
                                <label class="row mt-3 mb-0" for="senhaUsuario">Informe sua senha de Usuario:</label>
                                <div class="row d-flex justify-content-between">
                                    <b-form-input v-model="senha" id="senhaUsuario" type="password" class="p-0 px-1 w-50" label="teste" placeholder=" Informe sua senha..."></b-form-input>
                                    <b-button variant="success" @click="despausar">Finalizar Pausa</b-button>
                                </div>
                            </b-container>
                        </template>
                    </b-modal>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import BotaoIcone from '@/components/botoes/BotaoIcone.vue'
    import UsuariosPausadosServices from '@/assets/js/services/UsuariosPausadosServices'
    import usuarioLogado from '@/assets/js/providers/usuario-logado';
	import ChatHub from "@/assets/js/hubs/ChatHub";

    export default {
        name: 'MenuTop',
        components: {
            BotaoIcone
        },

        props: {
            atendimentoDisponivel: {
                type: Boolean,
            },
            usuarioWhatsWeb: { type: Boolean, }
        },

        data() {
            return {
                segundos: 0,
                minutos: 0,
                countdown: null,
                senha: '',
                tempoLimite: '',
                nome: '',
                loading: false
            }
        },

        computed: {
            ...mapGetters({
                dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
                contagem: 'usuariosPausados/contagem',
                getIsUsuarioPausado: 'usuariosPausados/getIsUsuarioPausado',
                getPausas: 'pausas/getPausas',
                getNomePausa: 'usuariosPausados/getNomePausa'

            }),
            usuarioLogado() {
                return usuarioLogado?.usuarioNome ?? 'Sem Nome'
            }
        },

        created() {
            this.verificarUsuarioPausado();
        },

        methods: {
            ...mapActions({
                pausarStore: 'usuariosPausados/pausar',
                verificarUsuarioPausadoStore: 'usuariosPausados/verificarUsuarioPausado',
                zerarInicioStore: 'usuariosPausados/zerarInicio',
                buscarPausasStore: 'pausas/buscarPausas'
            }),
            async buscarPausas() {
                await this.buscarPausasStore();
            },
            async verificarUsuarioPausado() {
                await this.verificarUsuarioPausadoStore();
                if (this.getIsUsuarioPausado) {
                    this.segundos = parseInt(this.contagem.split(':')[2]);
                    this.minutos = parseInt(this.contagem.split(':')[1]);
                    await this.startTimer();
                } else {
                    await this.buscarPausasStore();
                }
            },
            async pausar(item) {
                this.nome = item.Nome
                this.tempoLimite = item.TempoLimite
                var dadosUsuarioPausado = {
                    PausaId: item.Id,
                    UsuarioId: this.dadosUsuarioAutenticado.usuarioId,
                    EmpresaId: this.dadosUsuarioAutenticado.empresaId,
                    Fim: null
                }
                await UsuariosPausadosServices.pausar(dadosUsuarioPausado)
                await this.verificarUsuarioPausadoStore()
                await this.pausarStore(true)
                await this.startTimer();
                if (this.$route.name == "AtendimentoPage") {
                    ChatHub.connect().then(connection => {
                        connection.paralisarFilaDeAtendimento();
                        connection.disconnect();
                    });
                }
            },
            startTimer() {
                this.countdown = setInterval(() => {
                    this.segundos++
                    if (this.segundos === 60) {
                        this.segundos = 0
                        this.minutos++
                    }
                }, 1000);

            },
            async despausar() {
                var dadosUsuarioDespausado = {
                    UsuarioId: this.dadosUsuarioAutenticado.usuarioId,
                    Senha: this.senha
                }
                UsuariosPausadosServices.despausar(dadosUsuarioDespausado).then(res => {
                    if (!res.Sucesso) {
                        this.$bvToast.toast(res.Mensagem, {
                            title: 'Falha ao Logar',
                            variant: 'danger',
                            solid: true
                        })
                    } else {
                        this.pausarStore(false)
                        clearInterval(this.countdown);
                        this.senha = '';
                        this.minutos = 0;
                        this.segundos = 0;
                        this.zerarInicioStore();
                    }
                }).catch(e => {
                    console.log(e, "erro")
                });
                await this.buscarPausasStore();
            }
        }
    }

</script>

<style>
    .menu-top-container {
        position: relative;
        z-index: 5;
        height: 56px;
    }

        .menu-top-container .container-fluid {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.30);
            position: fixed;
            height: 56px;
            width: 100%;
        }

    .menu-top-row {
        padding: 8px;
        position: relative;
    }

    .menu-top-item {
        color: white;
        vertical-align: middle;
    }

    .menu-top-icone {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255, 1)' stroke-linecap='square' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat;
        background-position: center;
    }

    .menu-top-link-home {
        text-decoration: none !important;
        margin-left: 6px;
    }

    .menu-top-titulo-head {
        color: #fff;
        vertical-align: middle;
        font-size: medium;
    }

    .menu-top-botao-atendimento {
        color: #fff;
    }

    .menu-top-dropdown {
        background: none !important;
        font-size: 28px;
    }

        .menu-top-dropdown button,
        .menu-top-dropdown button:hover,
        .menu-top-dropdown button:focus {
            background: none !important;
            border: none;
            outline: 0;
        }

        .menu-top-dropdown button {
            font-weight: bold;
            text-transform: uppercase;
        }

            .menu-top-dropdown button:hover {
                background-color: rgba(0, 0, 0, 0.04) !important;
            }

            .menu-top-dropdown button:active {
                animation: rippleplay 600ms linear;
            }

    @keyframes rippleplay {
        to {
            transform: scale(0.9);
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    #caixa_carregamento {
        width: 100%;
        position: absolute;
        background: #000;
        display: none;
        background-color: rgba(0,0,0,.3);
        z-index: 9999 !important;
        left: 0;
        height: 100vh;
    }

    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        margin: calc(50vh - 60px) auto;
        z-index: 9999;
    }

    .scroll-dropdown .dropdown-menu {
        width: 220px;
        max-height: 200px;
        overflow-y: scroll;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>