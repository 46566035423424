const ConsultaAtendimentosPage = () => import(/* webpackChunkName: "consulta-atendimentos-page" */ '@/views/consultas/ConsultaAtendimentosPage.vue')
const ConsultaMensagensPage = () => import(/* webpackChunkName: "consulta-mensagens-page" */ '@/views/consultas/ConsultaMensagensPage.vue')
const ConsultaWhatsBusinessPage = () => import(/* webpackChunkName: "consulta-whatsbusiness-page" */ '@/views/consultas/ConsultaWhatsBusinessPage.vue')
const FunilPage = () => import(/* webpackChunkName: "funil-page" */ '@/views/consultas/FunilPage.vue')
const TestePage = () => import(/* webpackChunkName: "funil-page" */ '@/views/consultas/TestesPage.vue')
const HistoricoUsuario = () => import(/* webpackChunkName: "funil-page" */ '@/views/consultas/HistoricoUsuario.vue')
const Graficos = () => import(/* webpackChunkName: "funil-page" */ '@/views/consultas/Graficos.vue')
const ConsultaMensagensSProtocolo = () => import(/* webpackChunkName: "consulta-mensagenssprotocolo-page" */ '@/views/consultas/ConsultaMensagensSProtocolo.vue')
const RelatorioUsuariosPausasPage = () => import(/* webpackChunkName: "relatorio-usuarios-pausas-page" */ '@/views/consultas/RelatorioUsuariosPausasPage.vue')
const Relatorios = () => import(/* webpackChunkName: "consulta-mensagenssprotocolo-page" */ '@/views/consultas/Relatorios.vue')
const ConsultaAtendimentosClientePage = () => import(/* webpackChunkName: "consulta-atendimentos-cliente-page" */ '@/views/consultas/ConsultaAtendimentosClientePage.vue')
const ConsultaHistoricoCampanhasPage = () => import(/* webpackChunkName: "consulta-historico-campanhas-page" */ '@/views/consultas/ConsultaHistoricoCampanhasPage.vue')
const UsoTelefonico = () => import(/* webpackChunkName: "uso-telefonico-page" */ '@/views/consultas/UsoTelefonicoPage.vue');

export default [
    {
        path: '/consulta-atendimentos',
        name: 'ConsultaAtendimentosPage',
        component: ConsultaAtendimentosPage
    },
    {
        path: '/consulta-atendimentos-cliente',
        name: 'ConsultaAtendimentosClientePage',
        component: ConsultaAtendimentosClientePage
    },
    {
        path: '/consulta-mensagens',
        name: 'ConsultaMensagensPage',
        component: ConsultaMensagensPage
    },
    {
        path: '/consulta-whatsappbusiness',
        name: 'ConsultaWhatsBusinessPage',
        component: ConsultaWhatsBusinessPage
    },
    {
        path: '/consulta-msg-sprotocolo',
        name: 'ConsultaMensagensSProtocolo',
        component: ConsultaMensagensSProtocolo
    },
    {
        path: '/relatorio-usuarios-pausas',
        name: 'RelatorioUsuariosPausasPage',
        component: RelatorioUsuariosPausasPage
    },
    {
        path: '/relatorios',
        name: 'Relatorios',
        component: Relatorios
    },
    {
        path: '/relatorios/usuario/:usuarioId',
        name: 'RelatoriosUsuario',
        component: Relatorios
    },
    {
        path: '/relatorios/setor/:setorId',
        name: 'RelatoriosSetor',
        component: Relatorios
    },
    {
        path: '/funil',
        name: 'FunilPage',
        component: FunilPage
    },
    {
        path: '/teste',
        name: 'TestePage',
        component: TestePage
    },
    {
        path: '/historico_usuario',
        name: 'HistoricoUsuario',
        component: HistoricoUsuario
    },
    {
        path: '/graficos',
        name: 'Graficos',
        component: Graficos
    },
    {
        path: '/consulta-historico-campanhas/:campanhaId?',
        name: 'ConsultaHistoricoCampanhasPage',
        component: ConsultaHistoricoCampanhasPage
    },
    {
        path: "/uso-telefonico",
        name: "UsoTelefonico",
        component: UsoTelefonico
    }
]
