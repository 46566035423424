const MensagensEmailPage = () => import(/* webpackChunkName: "mensagens-email-page" */ '@/views/campanhas/MensagensEmailPage.vue');
const MensagensPadraoPage = () => import(/* webpackChunkName: "mensagens-padrao-page" */ '@/views/campanhas/MensagensPadraoPage.vue');

const TemplatesWhatsBusinessPage = () => import(/* webpackChunkName: "templates-whats-business-page" */ '@/views/campanhas/TemplatesWhatsBusinessPage.vue');
const TemplatesSMS = () => import(/* webpackChunkName: "templates-whats-business-page" */ '@/views/campanhas/TemplatesSMS.vue');
const TemplatesWhatsConvencionalPage = () => import(/* webpackChunkName: "templates-whats-convencional-page" */ '@/views/campanhas/TemplatesWhatsConvencionalPage.vue');
const TemplatesEmailPage = () => import(/* webpackChunkName: "templates-email-page" */ '@/views/campanhas/TemplatesEmailPage.vue');
const PerfilCampanhaPage = () => import(/* webpackChunkName: "perfil-campanha-page" */ '@/views/campanhas/PerfilCampanhaPage.vue');
const DiscadorAutomaticoPage = () => import(/* webpackChunkName: "discador-automatico-page" */ '@/views/campanhas/DiscadorAutomaticoPage.vue');

export default [
	{
		path: '/mensagens-email',
		name: 'MensagensEmailPage',
		component: MensagensEmailPage
	},
	{
		path: '/mensagens-padrao',
		name: 'MensagensPadraoPage',
		component: MensagensPadraoPage
	},
	{
		path: '/campanhas-whatsapp',
		name: 'TemplatesWhatsBusinessPage',
		component: TemplatesWhatsBusinessPage
	},
	{
		path: '/campanhas-whatsapp-convencional',
		name: 'TemplatesWhatsConvencionalPage',
		component: TemplatesWhatsConvencionalPage
	},
	{
		path: '/campanhas-sms',
		name: 'TemplatesSMS',
		component: TemplatesSMS
	},
	{
		path: '/campanhas-email-template',
		name: 'TemplatesEmailPage',
		component: TemplatesEmailPage
	},
	{
		path: '/perfil-campanha',
		name: 'PerfilCampanhaPage',
		component: PerfilCampanhaPage
	},
	{
		path: '/discador-automatico',
		name: 'DiscadorAutomaticoPage',
		component: DiscadorAutomaticoPage
	}
]
