<template>
    <button class="botao-redondo" v-on:click="$emit('click')">
        <i class="far botao-redondo-icone"
            :class="classeIcone"
        >
        </i>
    </button>
</template>

<script>
export default {
  name: 'BotaoIcone',
    props:
    {
        classeIcone: String
    }
}
</script>

<style scoped>
.botao-redondo,
.botao-redondo:hover,
.botao-redondo:focus {
    background-color: transparent;
    border: none;
    outline: 0;
}

.botao-redondo {
    position: relative;
    border-radius: 50%;
    padding: 4px;
    transition: all 0.4s ease-in;
}
.botao-redondo::before {
    content: '';
    background-color: aliceblue;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0.001, 0.001);
}
.botao-redondo:focus::before {
    animation: effect_dylan 0.8s ease-out;
}

.botao-redondo:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.botao-redondo-icone {
    font-size: 28px;
    vertical-align: middle;
    position: relative;
}

@keyframes effect_dylan {
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
</style>
